import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery, useApolloClient } from "@apollo/react-hooks";
import { Button, Icon, Message } from "semantic-ui-react";
import styled from "styled-components";
import useOnlineStatus from "@rehooks/online-status";

import {
  BULK_RATE_VINE_HASURA,
  UPDATE_VINE_RATING_HASURA,
  CREATE_VINE_ONLINE,
  EDIT_VINE_ONLINE,
  REMOVE_OFFLINE_RATING,
  DELETE_VINE_ONLINE,
} from "../../../constants/queries";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & .message {
    margin-bottom: 0;
  }
`;

const Spacer = styled.div`
  width: 10px;
`;

const OFFLINE_RATINGS_ONLY = gql`
  query getOfflineRatings {
    offlineRatings @client {
      id
      createdBy
      rating
      traitTierId
      vine
      vineId
      polygonId
      comment
      pseudoId
      row
      traitId
      numericalValue
      textValue
    }
  }
`;

export const OFFLINE_RATINGS = gql`
  query getOfflineRatings {
    offlineRatings @client {
      id
      createdBy
      rating
      traitTierId
      vine
      vineId
      polygonId
      comment
      traitId
      numericalValue
      textValue
      pseudoId
      row
    }
    offlineCreateVines @client {
      newVineNumber
      rowId
      location {
        longitude
        latitude
      }
    }
    offlineEditVines @client {
      vineNumber
      rowId
      location {
        longitude
        latitude
      }
    }
    offlineDeleteVines @client {
      vineNumber
      rowId
    }
  }
`;

const REMOVE_OFFLINE_CREATED_VINE = gql`
  mutation removeOfflineCreatedVine($vineNumber: Int!, $rowId: Int!) {
    removeOfflineCreatedVine(vineNumber: $vineNumber, rowId: $rowId) @client
  }
`;

const REMOVE_OFFLINE_EDITED_VINE = gql`
  mutation removeOfflineEditedVine($vineNumber: Int!, $rowId: Int!) {
    removeOfflineEditedVine(vineNumber: $vineNumber, rowId: $rowId) @client
  }
`;

const REMOVE_OFFLINE_DELETED_VINE = gql`
  mutation removeOfflineDeletedVine($vineNumber: Int!, $rowId: Int!) {
    removeOfflineDeletedVine(vineNumber: $vineNumber, rowId: $rowId) @client
  }
`;

/**
 *
 * @param {array} inputArray
 * @param {number} chunkSize
 */
const chunkUpInputArray = (inputArray, chunkSize) => {
  return new Array(Math.ceil(inputArray.length / chunkSize))
    .fill()
    .map((_) => [...inputArray.splice(0, chunkSize)]);
};

const OfflineRatings = () => {
  const { data } = useQuery(OFFLINE_RATINGS);
  const online = useOnlineStatus();
  const [isSyncing, setIsSyncing] = useState(false);
  const client = useApolloClient();

  console.log({ data });

  const [removeOfflineRating] = useMutation(REMOVE_OFFLINE_RATING);
  const [removeOfflineCreatedVine] = useMutation(REMOVE_OFFLINE_CREATED_VINE);
  const [removeOfflineEditedVine] = useMutation(REMOVE_OFFLINE_EDITED_VINE);
  const [removeOfflineDeletedVine] = useMutation(REMOVE_OFFLINE_DELETED_VINE);

  const [createVineRating] = useMutation(BULK_RATE_VINE_HASURA, {
    update: (_cache, { data }) => {
      const vineRatings = data.insert_rating_vinerating.returning;
      vineRatings.forEach((vineRating) => {
        const variables = {
          vineNumber: vineRating?.vineyard_vine?.number || null,
          rowId: vineRating?.vineyard_vine?.vineyard_row?.id || null,
          polygonId: vineRating?.vineyard_polygon?.id || null,
        };
        removeOfflineRating({ variables });
      });
    },
  });

  const [updateVineRating] = useMutation(UPDATE_VINE_RATING_HASURA, {
    update: (_cache, { data }) => {
      const vineRating = data.update_rating_vinerating_by_pk;
      const variables = {
        vineNumber: vineRating?.vineyard_vine?.number || null,
        rowId: vineRating?.vineyard_vine?.vineyard_row?.id || null,
        polygonId: vineRating?.vineyard_polygon?.id || null,
      };
      removeOfflineRating({ variables });
    },
  });

  const [createVines] = useMutation(CREATE_VINE_ONLINE, {
    update: (proxy, mutationResult) => {
      const { result } = mutationResult.data.createVines;
      console.log("create vine result", result);

      const prev = proxy.readQuery({
        query: OFFLINE_RATINGS_ONLY,
      });
      console.log("fucking prev", prev);
      const data = { ...prev };

      // add real id to offline rating
      result.vines.forEach((vine) => {
        const variables = {
          vineNumber: vine.number,
          rowId: parseInt(vine.row.id),
        };
        // the rating of which the id of the vine has to be set to the true id from DB
        const affectedRatingIndex = prev.offlineRatings.findIndex(
          (rating) =>
            rating.vine === vine.number && rating.row === parseInt(vine.row.id)
        );
        console.log("index", affectedRatingIndex);
        if (affectedRatingIndex >= 0) {
          console.log(
            "affected offline",
            data.offlineRatings[affectedRatingIndex]
          );
          console.log("respective vine", vine);
          //   console.log(data.offlineRatings[affectedRatingIndex].vineId, vine.id);
          data.offlineRatings[affectedRatingIndex].vineId = parseInt(vine.id);
        }
        removeOfflineCreatedVine({ variables });
      });

      console.log("new offline", data.offlineRatings);
      proxy.writeData({ data });
    },
  });

  const [editVines] = useMutation(EDIT_VINE_ONLINE, {
    update: (proxy, mutationResult) => {
      const { result } = mutationResult.data.editVines;

      result.vines.forEach((vine) => {
        const variables = {
          vineNumber: vine.number,
          rowId: parseInt(vine.row.id),
        };
        removeOfflineEditedVine({ variables });
      });
    },
  });

  const [deleteVines] = useMutation(DELETE_VINE_ONLINE, {
    update: (proxy, mutationResult) => {
      const { result } = mutationResult.data.deleteVines;

      result.deletedVines.forEach((vine) => {
        const variables = {
          vineNumber: vine.vineNumber,
          rowId: vine.rowId,
        };
        removeOfflineDeletedVine({ variables });
      });
    },
  });

  const syncDatapoints = async () => {
    setIsSyncing(true);
    await syncOfflineCreateVines();
    console.log(">>>>>> created vines offline synced");
    await syncOfflineEditedVines();
    console.log(">>>>>> edited vines offline synced");
    await syncOfflineDeletedVines();
    console.log(">>>>>> deleted vines offline synced");
    await syncOfflineRatings();
    console.log(">>>>>> rated vines offline synced");
    setIsSyncing(false);
  };

  const syncOfflineRatings = async () => {
    /////////////////////
    // CREATE NEW RATINGS
    /////////////////////

    // read from cache again to make sure we're up to date
    const prev = client.readQuery({
      query: OFFLINE_RATINGS_ONLY,
    });

    // those to create have a string pseudo id
    const ratingsToCreate = prev.offlineRatings.filter(
      (rating) => typeof rating.id === "string"
    );

    const ratingsToCreateInput = ratingsToCreate.map((rating) => {
      const input = {
        comment: rating.comment || "",
        created: new Date().toISOString(),
        created_by_id: rating.createdBy,
        rating_id: rating.rating,
        trait_tier_id: rating.traitTierId,
        vine_id: rating?.vineId || null,
        polygon_id: rating?.polygonId || null,
        numerical_value: rating?.numericalValue || null,
        text_value: rating?.textValue || null,
        trait_id: rating?.traitId || null,
      };
      console.log("input", input);
      return input;
    });

    const createInputChunks = chunkUpInputArray(ratingsToCreateInput, 50);

    // inspiration: https://stackoverflow.blog/2019/09/12/practical-ways-to-write-better-javascript/
    await Promise.all(
      createInputChunks.map(async (chunk) => {
        try {
          await createVineRating({ variables: { input: chunk } });
          //   await bulkRateVine({ variables: { input: { vineRatings: chunk } } });
        } catch (error) {
          console.log("sync ratings", error);
        }
      })
    );

    //////////////////////////
    // UPDATE EXISTING RATINGS
    //////////////////////////

    // those to update have a number real id
    const ratingsToUpdate = data.offlineRatings.filter(
      (rating) => typeof rating.id === "number"
    );
    ratingsToUpdate.forEach(async (rating) => {
      const input = {
        comment: rating.comment || "",
        trait_tier_id: rating.traitTierId,
      };
      const pk_columns = { id: rating.id };
      await updateVineRating({
        variables: { pk_columns, input },
      });
    });
  };

  const syncOfflineCreateVines = async () => {
    const bulkInput = data.offlineCreateVines.map((vine) => {
      const input = { ...vine };
      delete input.__typename;
      delete input.location.__typename;
      return input;
    });

    const chunkSize = 50;
    const inputChunks = new Array(Math.ceil(bulkInput.length / chunkSize))
      .fill()
      .map((_) => [...bulkInput.splice(0, chunkSize)]);

    await Promise.all(
      inputChunks.map(async (chunk) => {
        try {
          await createVines({ variables: { input: { vines: chunk } } });
        } catch (error) {
          console.log("create error", error);
        }
      })
    );
  };

  const syncOfflineEditedVines = async () => {
    const bulkInput = data.offlineEditVines.map((vine) => {
      const input = { ...vine };
      delete input.__typename;
      delete input.location.__typename;
      return input;
    });

    const chunkSize = 50;
    const inputChunks = new Array(Math.ceil(bulkInput.length / chunkSize))
      .fill()
      .map((_) => [...bulkInput.splice(0, chunkSize)]);

    await Promise.all(
      inputChunks.map(async (chunk) => {
        try {
          await editVines({ variables: { input: { vines: chunk } } });
        } catch (error) {
          console.log("sync edit", error);
        }
      })
    );
  };

  const syncOfflineDeletedVines = async () => {
    const bulkInput = data.offlineDeleteVines.map((vine) => {
      const input = { ...vine };
      delete input.__typename;
      return input;
    });

    const chunkSize = 50;
    const inputChunks = new Array(Math.ceil(bulkInput.length / chunkSize))
      .fill()
      .map((_) => [...bulkInput.splice(0, chunkSize)]);

    await Promise.all(
      inputChunks.map(async (chunk) => {
        try {
          await deleteVines({ variables: { input: { vines: chunk } } });
        } catch (error) {
          console.log("sync delete", error);
        }
      })
    );
  };

  if (
    (data && data.offlineRatings && data.offlineRatings.length) ||
    (data.offlineCreateVines && data.offlineCreateVines.length) ||
    (data.offlineEditVines && data.offlineEditVines.length) ||
    (data.offlineDeleteVines && data.offlineDeleteVines.length)
  ) {
    return (
      <Wrapper>
        <Message color="yellow" size="tiny">
          <Icon name="warning circle" />
          {isSyncing
            ? "Syncing datapoins... be patient."
            : "You have unsynced datapoints."}
        </Message>
        <Spacer />
        <Button
          disabled={!online || isSyncing}
          loading={isSyncing}
          color="green"
          onClick={syncDatapoints}
        >
          Sync now
        </Button>
      </Wrapper>
    );
  }
  return null;
};

export default OfflineRatings;
