import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { GeoJSONLayer } from "react-mapbox-gl";
import {
  lineString,
  point,
  multiPoint,
  multiLineString,
  featureCollection,
} from "@turf/helpers";
import bbox from "@turf/bbox";
import buffer from "@turf/buffer";
import bboxPolygon from "@turf/bbox-polygon";

import { useVineRatingMapping } from "./../../../../../../context/vineRatingMapping";
import { useActiveRating } from "./../../../../../../context/activeRating";
import {
  ACTIVE_COLOR,
  INACTIVE_COLOR,
} from "./../../../../../../constants/colors";
import { RATING_QUERY_HASURA } from "./../../../../../../context/activeRating";

const VINE_RADIUS = 5;

const hasRating = (activeTrait, vine, vineRatingMapping, rowId) => {
  const key = `${activeTrait.trait_trait.id}-${rowId}-${vine.number}`;
  const rating = vineRatingMapping[key];
  return (
    rating !== undefined && rating.trait_traittier && rating.trait_traittier.id
  );
};

const VineyardGeojson = ({ ratingId, activeRow }) => {
  const [vineyardFeatures, setVineyardFeatures] = useState(null);
  const [activeRowFeatures, setActiveRowFeatures] = useState(null);

  const { data } = useQuery(RATING_QUERY_HASURA, {
    variables: { id: `${ratingId}` },
    fetchPolicy: "cache-only",
  });
  const { activeVine, activeTrait } = useActiveRating();
  const { vineRatingMapping } = useVineRatingMapping();

  // assemble active row
  useEffect(() => {
    if (data && data.rating_rating_by_pk) {
      const activeRowCoords = [];
      const vines = activeRow.vineyard_vines;
      // all vines that have not been rated yet
      const rowVineCoords = vines
        .filter(
          (v) => !hasRating(activeTrait, v, vineRatingMapping, activeRow.id)
        )
        .map((v) => v.location.coordinates);

      // all vines that have been rated
      const ratedVinesInRow = vines
        .filter((v) =>
          hasRating(activeTrait, v, vineRatingMapping, activeRow.id)
        )
        .map((v) => ({
          coords: v.location.coordinates,
          color:
            vineRatingMapping[
              `${activeTrait.trait_trait.id}-${activeRow.id}-${v.number}`
            ].trait_traittier.color,
        }));

      const vineCoordsGeojson = multiPoint(rowVineCoords, {
        circleColor: INACTIVE_COLOR,
        circleRadius: VINE_RADIUS,
        circleStrokeColor: "transparent",
      });

      const ratedVineCoordsPoints = ratedVinesInRow.map((v) =>
        point(v.coords, {
          circleColor: v.color,
          circleRadius: VINE_RADIUS,
          circleStrokeColor: "transparent",
        })
      );

      const firstVine = activeRow.vineyard_vines[0];
      const lastVine =
        activeRow.vineyard_vines[activeRow.vineyard_vines.length - 1];
      activeRowCoords.push(firstVine.location.coordinates);
      activeRowCoords.push(lastVine.location.coordinates);
      const activeRowGeojson = lineString([...activeRowCoords], {
        lineColor: ACTIVE_COLOR,
        circleColor: "transparent",
        circleStrokeColor: "transparent",
      });

      const features = [
        activeRowGeojson,
        vineCoordsGeojson,
        ...ratedVineCoordsPoints,
      ];

      if (activeRow.number && activeVine.number) {
        const activeVineCoords = activeVine.location.coordinates;
        let activeVineColor = INACTIVE_COLOR;
        if (
          vineRatingMapping[
            `${activeTrait.trait_trait.id}-${activeRow.id}-${activeVine.number}`
          ] &&
          vineRatingMapping[
            `${activeTrait.trait_trait.id}-${activeRow.id}-${activeVine.number}`
          ].trait_traittier
        ) {
          activeVineColor =
            vineRatingMapping[
              `${activeTrait.trait_trait.id}-${activeRow.id}-${activeVine.number}`
            ].trait_traittier.color;
        }

        //   ? vineRatingMapping[`${activeRow.id}-${activeVine.number}`]
        //       .trait_traittier.color
        //   : INACTIVE_COLOR;
        const activeVineCoordsGeojson = point(activeVineCoords, {
          circleColor: activeVineColor,

          circleRadius: VINE_RADIUS,
          lineColor: "transparent",
          circleStrokeColor: "black",
        });
        features.push(activeVineCoordsGeojson);
      }
      const allFeatures = featureCollection(features);
      setActiveRowFeatures(allFeatures);
    }
  }, [activeRow, activeVine, vineRatingMapping, activeTrait]); // eslint-disable-line react-hooks/exhaustive-deps

  // assemble all but active rows
  useEffect(() => {
    if (data && data.rating_rating_by_pk) {
      const vineyard = data.rating_rating_by_pk.vineyard_vineyard;
      const rows = vineyard.vineyard_rows;

      let vineCoords = [];
      let ratedVines = [];

      //   const activeRowCoords = [];
      const otherRowsCoords = [];

      const inactiveRows = rows.filter((r) => r.id !== activeRow.id);
      // assemble coordinates for all rows
      for (const row of inactiveRows) {
        const vines = row.vineyard_vines;
        // let vines;
        // if (row.number === activeRow.number) {
        //   if (activeVine.number) {
        //     vines = row.vineyard_vines.filter(
        //       (v) => v.number !== activeVine.number
        //     );
        //   } else {
        //     vines = row.vineyard_vines;
        //   }
        // } else {
        //   vines = row.vineyard_vines;
        // }

        // all vines that have not been rated yet
        const rowVineCoords = vines
          .filter((v) => !hasRating(activeTrait, v, vineRatingMapping, row.id))
          .map((v) => v.location.coordinates);

        // all vines that have been rated
        const ratedVinesInRow = vines
          .filter((v) => hasRating(activeTrait, v, vineRatingMapping, row.id))
          .map((v) => ({
            coords: v.location.coordinates,
            color:
              vineRatingMapping[
                `${activeTrait.trait_trait.id}-${row.id}-${v.number}`
              ].trait_traittier.color,
          }));

        // add to global lists
        vineCoords = [...vineCoords, ...rowVineCoords];
        ratedVines = [...ratedVines, ...ratedVinesInRow];

        const firstVine = row.vineyard_vines[0];
        const lastVine = row.vineyard_vines[row.vineyard_vines.length - 1];
        // activeRowCoords.push(firstVine.location.coordinates);
        // activeRowCoords.push(lastVine.location.coordinates);
        const start = firstVine.location.coordinates;
        const end = lastVine.location.coordinates;
        const line = [start, end];
        otherRowsCoords.push(line);
      }

      const vineCoordsGeojson = multiPoint(vineCoords, {
        circleColor: INACTIVE_COLOR,
        circleRadius: VINE_RADIUS,
        circleStrokeColor: "transparent",
      });

      const ratedVineCoordsPoints = ratedVines.map((v) =>
        point(v.coords, {
          circleColor: v.color,
          circleRadius: VINE_RADIUS,
          circleStrokeColor: "transparent",
        })
      );

      const otherRowsGeojson = multiLineString(otherRowsCoords, {
        lineColor: INACTIVE_COLOR,
        circleColor: "transparent",
        circleStrokeColor: "transparent",
      });

      // POLES
      const poles = vineyard.vineyard_rows.map((r) => r.vineyard_poles);
      const flatPoles = [].concat(...poles.map((p) => p));
      const poleCoords = flatPoles.map((p) => p.location.coordinates);

      const RECT_SIZE = 0.0002;
      const UNITS = "kilometers";

      const poleFeatures = poleCoords.map((p) => {
        const polePoint = point(p);
        const buffered = buffer(polePoint, RECT_SIZE, { units: UNITS });
        const bufferedBbox = bbox(buffered);
        const bufferedBboxPolygon = bboxPolygon(bufferedBbox, {
          properties: {
            color: "#fbbd08",
            circleColor: "transparent",
            circleStrokeColor: "transparent",
            lineColor: "transparent",
          },
        });
        return bufferedBboxPolygon;
      });

      const features = [
        ...poleFeatures,
        vineCoordsGeojson,
        ...ratedVineCoordsPoints,
        otherRowsGeojson,
        // activeRowGeojson,
      ];

      //   // assemble all features
      const allFeatures = featureCollection(features);
      setVineyardFeatures(allFeatures);
    }
  }, [activeRow, activeTrait, vineRatingMapping]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {vineyardFeatures ? (
        <GeoJSONLayer
          data={vineyardFeatures}
          fillPaint={{
            "fill-color": ["get", "color"],
          }}
          circlePaint={{
            "circle-color": ["get", "circleColor"],
            "circle-radius": ["get", "circleRadius"],
            "circle-stroke-color": ["get", "circleStrokeColor"],
            "circle-stroke-width": 4,
          }}
          linePaint={{ "line-color": ["get", "lineColor"], "line-width": 2 }}
        />
      ) : null}
      {activeRowFeatures ? (
        <GeoJSONLayer
          data={activeRowFeatures}
          fillPaint={{
            "fill-color": ["get", "color"],
          }}
          circlePaint={{
            "circle-color": ["get", "circleColor"],
            "circle-radius": ["get", "circleRadius"],
            "circle-stroke-color": ["get", "circleStrokeColor"],
            "circle-stroke-width": 4,
          }}
          linePaint={{ "line-color": ["get", "lineColor"], "line-width": 2 }}
        />
      ) : null}
    </>
  );
};

VineyardGeojson.propTypes = {
  ratingId: PropTypes.number.isRequired,
  activeRow: PropTypes.object.isRequired,
};

export default VineyardGeojson;
