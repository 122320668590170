import React, { useState, useMemo } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";
import { Formik, Form as FormikForm } from "formik";
import styled from "styled-components";
import useOnlineStatus from "@rehooks/online-status";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ADD_TRAIT = gql`
  mutation addTrait($input: CreateOrUpdateTraitMutationInput!) {
    createOrUpdateTrait(input: $input) {
      errors {
        field
        messages
      }
      id
      name
    }
  }
`;

const GET_TRAITS = gql`
  {
    viewer {
      traits {
        id
        name
        isNumerical
        isText
        traitTiers {
          order
          name
        }
      }
      projects {
        id
        name
      }
    }
  }
`;

const NewTraitModal = () => {
  const [showModal, setShowModal] = useState(false);
  const online = useOnlineStatus();
  const { data } = useQuery(GET_TRAITS, {
    fetchPolicy: "cache-and-network",
  });
  const [createOrUpdateTrait, { error }] = useMutation(ADD_TRAIT, {
    onCompleted: () => setShowModal(false),
    update: (cache, { data: { createOrUpdateTrait } }) => {
      const { viewer } = cache.readQuery({ query: GET_TRAITS });
      viewer.traits = viewer.traits.concat([
        { ...createOrUpdateTrait, traitTiers: [] },
      ]);
      cache.writeQuery({
        query: GET_TRAITS,
        data: { viewer },
      });
    },
  });

  const projectOptions = useMemo(() => {
    if (data && data.viewer) {
      return data.viewer.projects.map((project) => ({
        key: project.id,
        value: project.id,
        text: project.name,
      }));
    }
    return [];
  }, [data]);

  return (
    <Modal
      trigger={
        <Button
          color="green"
          onClick={() => setShowModal(true)}
          disabled={!online}
        >
          add trait
        </Button>
      }
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <Modal.Header>Add trait</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            name: "",
            project: "",
            isNumerical: false,
            isText: false,
          }}
          onSubmit={(values, { setSubmitting }) => {
            createOrUpdateTrait({
              variables: {
                input: {
                  name: values.name,
                  project: values.project,
                  isNumerical: values.isNumerical,
                  isText: values.isText,
                },
              },
            });
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <FormikForm onSubmit={handleSubmit} className="ui large form">
              <InputWrapper>
                <Dropdown
                  selection
                  placeholder="Select project..."
                  fluid
                  value={values.project}
                  options={projectOptions}
                  onChange={(evt, data) => {
                    setFieldValue("project", data.value);
                  }}
                />
              </InputWrapper>
              <Form.Input
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                fluid
                focus={true}
              />
              <div style={{ display: "flex" }}>
                <Form.Checkbox
                  id="isNumerical"
                  label="Numerical values"
                  checked={values.isNumerical}
                  onChange={handleChange}
                  name="isNumerical"
                  style={{ marginRight: "1rem" }}
                  disabled={values.isText}
                />
                <Form.Checkbox
                  id="isText"
                  label="Text values"
                  checked={values.isText}
                  onChange={handleChange}
                  name="isText"
                  disabled={values.isNumerical}
                />
              </div>
              <ButtonWrapper>
                <Button onClick={() => setShowModal(false)}>cancel</Button>
                <Button
                  disabled={(isSubmitting && !error) || !values.name}
                  loading={isSubmitting && !error}
                  primary
                >
                  save
                </Button>
              </ButtonWrapper>
            </FormikForm>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default NewTraitModal;
