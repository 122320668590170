import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Modal } from "semantic-ui-react";
import { Formik, Form as FormikForm } from "formik";
import styled from "styled-components";
import { GET_RATINGS } from "..";

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const UPDATE_RATING = gql`
  mutation updateRating($input: CreateOrUpdateRatingMutationInput!) {
    __typename
    createOrUpdateRating(input: $input) {
      id
      created
      # trait
      vineyard
      name
      staff
      ratingDate
    }
  }
`;

const EditRatingModal = ({ rating, handleClose }) => {
  const [updateRating] = useMutation(UPDATE_RATING, {
    onCompleted: (data) => {
      handleClose();
    },
    update: (cache, { data: { createOrUpdateRating } }) => {
      // assemble new rating
      const name = createOrUpdateRating.name;

      const prev = cache.readQuery({ query: GET_RATINGS });

      const ratingIndex = prev.viewer.ratings.findIndex(
        (r) => r.id === rating.id
      );
      prev.viewer.ratings[ratingIndex].name = name;

      cache.writeQuery({
        query: GET_RATINGS,
        data: prev,
      });
    },
    refetchQueries: () => {
      return ["getRatings"];
    },
  });

  if (rating) {
    return (
      <Modal open={rating !== null} onClose={() => handleClose()}>
        <Modal.Header>edit rating</Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={{
              name: rating.name || "",
              staff: rating.staff || "",
              ratingDate: rating.ratingDate || "",
            }}
            onSubmit={(values) => {
              const input = {
                id: rating.id,
                // createdBy: rating.createdBy.id,
                name: values.name,
                staff: values.staff,
                ratingDate: new Date(values.ratingDate).toISOString(),
                // trait: rating.trait.id,
                // vineyard: rating.vineyard.id
              };
              updateRating({ variables: { input } });
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <FormikForm onSubmit={handleSubmit}>
                <InputWrapper>
                  <Form.Input
                    label="Name"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    fluid
                  />
                </InputWrapper>
                <InputWrapper>
                  <Form.Input
                    label="Staff"
                    type="text"
                    placeholder="Staff"
                    name="staff"
                    value={values.staff}
                    onChange={handleChange}
                    fluid
                  />
                </InputWrapper>
                <InputWrapper>
                  <Form.Input
                    label="Rating Date"
                    type="date"
                    name="ratingDate"
                    value={values.ratingDate.split("T")[0]}
                    onChange={handleChange}
                    fluid
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <Button onClick={() => handleChange()}>cancel</Button>
                  <Button
                    disabled={isSubmitting || !values.name}
                    loading={isSubmitting}
                    primary
                  >
                    save
                  </Button>
                </ButtonWrapper>
              </FormikForm>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

export default EditRatingModal;
