import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: red;
  color: white;
  text-align: center;
`;

const OfflineHint = () => {
  return (
    <Wrapper>
      <span>No network connection. Limited functionality.</span>
    </Wrapper>
  );
};

export default OfflineHint;
