import React from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import useOnlineStatus from "@rehooks/online-status";

import { useVineRatingMapping } from "./vineRatingMapping";

// why is this there?
import { OFFLINE_RATINGS } from "./../components/HeaderWrapper/OfflineRatings";

export const RATING_QUERY_HASURA = gql`
  query ratingAndVineyardHasura($id: Int!) {
    rating_rating_by_pk(id: $id) {
      id
      name
      vineyard_vineyard {
        geometry_types
        vineyard_polygons(order_by: { number: asc }) {
          id
          number
          geometry
          properties
        }
        name
        flik_number
        geotiff_id
        vineyard_rows(order_by: { number: asc }) {
          id
          number
          vineyard_vines(order_by: { number: asc }) {
            id
            location
            number
          }
          vineyard_poles {
            id
            location
          }
        }
      }
      rating_rating_traits {
        id
        trait_trait {
          id
          name
          is_numerical
          is_text
          trait_traittiers(order_by: { order: asc }) {
            color
            id
            name
          }
        }
      }
      rating_vineratings(
        order_by: { vineyard_vine: { row_id: asc, number: asc } }
      ) {
        comment
        numerical_value
        text_value
        created
        id
        vineyard_polygon {
          id
          number
        }
        vineyard_vine {
          number
          id
          vineyard_row {
            id
          }
        }
        trait_trait {
          id
        }
        trait_traittier {
          trait_trait {
            id
          }
          color
          id
          name
        }
      }
    }
  }
`;

/**
 *
 * @param {bool} online
 * @param {bool} existingOfflineRatings
 * @param {object} activeVine
 */
const getFetchPolicy = (online, existingOfflineRatings) => {
  if (!online || existingOfflineRatings) {
    return "cache-only";
    // return "cache-and-network";
  }
  return "network-only";
  //   if (online && activeVine !== null) {
  //     return "cache-first";
  //   }
  //   return "cache-and-network";
};

const ActiveRatingContext = React.createContext();

function ActiveRatingProvider(props) {
  const [activeRatingId, setActiveRatingId] = React.useState(null);
  const [activeTrait, setActiveTrait] = React.useState(null);
  const [activeRow, setActiveRow] = React.useState(null);
  const [activeVine, setActiveVine] = React.useState(null);
  const [activePolygon, setActivePolygon] = React.useState(null);
  const [cycleThroughTraits, setCycleThroughTraits] = React.useState(false);
  const [reversePolygonOrder, setReversePolygonOrder] = React.useState(false);

  const online = useOnlineStatus();
  const offlineRatings = useQuery(OFFLINE_RATINGS);

  const { initializeVineRatingMapping, initializePolygonRatingMapping } =
    useVineRatingMapping();

  // read query from cache if we're offline or if we have unsynced ratings.
  // idea: read from network only initially, e.g. when there's no active vine
  const fetchPolicy = React.useMemo(() => {
    let existingOfflineRatings = false;

    // this is just because of fucking Safari in development mode
    if (offlineRatings !== undefined) {
      if (offlineRatings.data) {
        if (offlineRatings.data.offlineRatings) {
          if (offlineRatings.data.offlineRatings.length) {
            existingOfflineRatings = true;
          }
        }
      }
    }
    return getFetchPolicy(online, existingOfflineRatings);
  }, [online, offlineRatings]);

  const [fetchActiveRating, { data, loading }] = useLazyQuery(
    RATING_QUERY_HASURA,
    {
      fetchPolicy,
    }
  );

  // what does this do?
  React.useEffect(() => {
    if (activeRatingId) {
      setActiveTrait(null);
      fetchActiveRating({ variables: { id: `${activeRatingId}` } });
    }
  }, [activeRatingId, fetchActiveRating]);

  React.useEffect(() => {
    if (!loading && data?.rating_rating_by_pk && !activeTrait) {
      const theActiveTrait = data.rating_rating_by_pk.rating_rating_traits[0];
      setActiveTrait(theActiveTrait);
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (activeTrait !== null) {
      const geometryType =
        data &&
        data.rating_rating_by_pk &&
        data.rating_rating_by_pk.vineyard_vineyard.geometry_types;
      if (geometryType === "points") {
        const vineRatings = data.rating_rating_by_pk.rating_vineratings.filter(
          (vr) => {
            return (
              vr?.trait_traittier?.trait_trait?.id ===
                activeTrait.trait_trait.id ||
              vr?.trait_trait?.id === activeTrait.trait_trait.id
            );
          }
        );
        initializeVineRatingMapping(activeTrait.trait_trait.id, vineRatings);
      } else if (geometryType === "polygons") {
        const polygonRatings =
          data.rating_rating_by_pk.rating_vineratings.filter((vr) => {
            return (
              (vr.trait_traittier &&
                vr.trait_traittier.trait_trait.id ===
                  activeTrait.trait_trait.id) ||
              (activeTrait.trait_trait.is_numerical &&
                vr.trait_trait.id === activeTrait.trait_trait.id) ||
              vr.trait_trait.id === activeTrait.trait_trait.id
            );
          });
        initializePolygonRatingMapping(
          activeTrait.trait_trait.id,
          polygonRatings
        );
      }
    }
  }, [activeTrait, data]);

  React.useEffect(() => {
    if (data && data.rating_rating_by_pk && !activePolygon) {
      const firstPolygon =
        data.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons[0];
      if (firstPolygon) {
        setActivePolygon(firstPolygon);
      }
    }
  }, [data, activePolygon]);

  return (
    <ActiveRatingContext.Provider
      value={{
        activeRatingId,
        setActiveRatingId,
        activeTrait,
        setActiveTrait,
        activeRating: data,
        loading,
        activeRow,
        setActiveRow,
        activeVine,
        setActiveVine,
        activePolygon,
        setActivePolygon,
        cycleThroughTraits,
        setCycleThroughTraits,
        reversePolygonOrder,
        setReversePolygonOrder,
      }}
      {...props}
    />
  );
}

const useActiveRating = () => React.useContext(ActiveRatingContext);

export { ActiveRatingProvider, useActiveRating };
