import * as React from "react";
import styled from "styled-components";
import { useMeasure } from "react-use";
import ReactMapboxGl from "react-mapbox-gl";
import center from "@turf/center";

import { useActiveRating } from "./../../../../context/activeRating";
import PolygonGeojson from "./PolygonGeojson";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiY3JlaW1lcnMiLCJhIjoiY2p4cHZjZDdhMGs3ZzNvcDlxdXhzNzJwbyJ9.bz6je21hT4R6nL6LNT4dKQ",
  trackResize: true,
});

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

const Properties = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
`;

export default function FieldMap() {
  const [ref, { height }] = useMeasure();
  const mapRef = React.useRef();
  const [zoom, setZoom] = React.useState(20);

  const { activePolygon } = useActiveRating();

  const mapCenter = React.useMemo(() => {
    if (activePolygon) {
      const c = center({ type: "Feature", geometry: activePolygon.geometry });
      return c.geometry.coordinates;
    }
    return null;
  }, [activePolygon]);

  React.useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.resize();
        }
      }, 100);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures effect only runs on mount and unmount

  return (
    <Wrapper ref={ref}>
      <Properties>
        {activePolygon && (
          <>{JSON.stringify(activePolygon.properties, null, 2)}</>
        )}
      </Properties>
      {height && mapCenter && (
        <Map
          onStyleLoad={(map, e) => {
            mapRef.current = map;
          }}
          style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line react/style-prop-object
          containerStyle={{
            height: `${height}px`,
          }}
          center={mapCenter}
          zoom={[zoom]}
          onZoomEnd={(map, e) => {
            setZoom(map.transform._zoom);
            // HACK
            map.transform.maxZoom = 23;
          }}
        >
          {/* <RasterLayer ratingId={ratingId} /> */}
          {/* <UserLocation /> */}
          {/* <VineyardGeojson activeRow={activeRow} ratingId={ratingId} /> */}
          <PolygonGeojson />
        </Map>
      )}
    </Wrapper>
  );
}
