import * as React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";
import { featureCollection } from "@turf/helpers";

import { ACTIVE_COLOR, INACTIVE_COLOR } from "./../../../../constants/colors";
import { useActiveRating } from "../../../../context/activeRating";
import { useVineRatingMapping } from "../../../../context/vineRatingMapping";

export default function PolygonGeojson() {
  const { activeRating, activeTrait, setActivePolygon, activePolygon } =
    useActiveRating();
  const { polygonRatingMapping } = useVineRatingMapping();

  const polygonsGeojson = React.useMemo(() => {
    if (!activeRating) {
      return null;
    }
    const themPolygons =
      activeRating.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons.filter(
        (p) => p.id !== activePolygon.id
      );
    const polygonCollection = featureCollection(
      themPolygons.map((p) => ({
        type: "Feature",
        geometry: p.geometry,
        properties: {
          id: p.id,
          ...p.properties,
          color: polygonRatingMapping[`${activeTrait.trait_trait.id}-${p.id}`]
            ? polygonRatingMapping[`${activeTrait.trait_trait.id}-${p.id}`]
                ?.trait_traittier?.color
            : INACTIVE_COLOR,
        },
      }))
    );
    return polygonCollection;
  }, [activeRating, activePolygon, polygonRatingMapping, activeTrait]);

  const activePolygonGeojson = React.useMemo(() => {
    if (!activePolygon) {
      return null;
    }
    return {
      type: "Feature",
      geometry: activePolygon.geometry,
      properties: {
        id: activePolygon.id,
        ...activePolygon.properties,
        color: polygonRatingMapping[
          `${activeTrait.trait_trait.id}-${activePolygon.id}`
        ]
          ? polygonRatingMapping[
              `${activeTrait.trait_trait.id}-${activePolygon.id}`
            ]?.trait_traittier?.color
          : ACTIVE_COLOR,
        lineColor: ACTIVE_COLOR,
      },
    };
  }, [activePolygon, polygonRatingMapping, activeTrait]);

  function handleClick(features = []) {
    if (features.length) {
      const feature = features[0];
      const { properties } = feature;
      const polygon =
        activeRating.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons.find(
          (p) => p.id === properties.id
        );
      if (polygon) {
        setActivePolygon(polygon);
      }
    }
  }

  return (
    <>
      {polygonsGeojson && (
        <GeoJSONLayer
          data={polygonsGeojson}
          fillOnClick={(e) => handleClick(e.features)}
          fillPaint={{
            "fill-color": ["get", "color"],
          }}
        />
      )}
      {activePolygonGeojson && (
        <GeoJSONLayer
          data={activePolygonGeojson}
          linePaint={{ "line-color": ["get", "lineColor"], "line-width": 4 }}
          fillPaint={{
            "fill-color": ["get", "color"],
          }}
        />
      )}
    </>
  );
}
