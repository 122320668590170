import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Popup, Icon } from "semantic-ui-react";
import useOnlineStatus from "@rehooks/online-status";

import { useVineRatingMapping } from "../../../context/vineRatingMapping";
import { useActiveRating } from "./../../../context/activeRating";

const propTypes = {
  vine: PropTypes.object,
  polygon: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

const CommentModal = ({ vine, polygon, handleSubmit }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const { vineRatingMapping, polygonRatingMapping } = useVineRatingMapping();
  const online = useOnlineStatus();
  const { activeRow, activeTrait } = useActiveRating();

  React.useEffect(() => {
    let rating;
    if (vine) {
      const key = `${activeTrait.trait_trait.id}-${activeRow.id}-${vine.number}`;
      rating = vineRatingMapping[key];
    } else if (polygon) {
      const key = `${activeTrait.trait_trait.id}-${polygon.id}`;
      rating = polygonRatingMapping[key];
    }
    if (rating && rating.comment) {
      setComment(rating.comment);
    } else {
      setComment("");
    }
  }, [
    vineRatingMapping,
    polygonRatingMapping,
    activeRow,
    vine,
    polygon,
    activeTrait,
  ]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Popup
          content={comment !== "" ? "edit comment" : "add comment"}
          trigger={
            <Button onClick={() => setOpen(true)} icon disabled={!online}>
              <Icon name="comment outline" />
            </Button>
          }
        />
      }
    >
      <Modal.Header>Add comment</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            placeholder="Comment"
            value={comment}
            onChange={(e, { value }) => setComment(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          cancel
        </Button>
        <Button
          primary
          loading={loading}
          disabled={loading}
          onClick={() => {
            setLoading(true);
            handleSubmit(comment);
            setLoading(false);
            setOpen(false);
          }}
        >
          save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CommentModal.propTypes = propTypes;

export default CommentModal;
