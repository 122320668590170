import gql from "graphql-tag";

export const REMOVE_OFFLINE_RATING = gql`
  mutation removeOfflineRating($vineNumber: Int, $rowId: Int, $polygonId: Int) {
    removeOfflineRating(
      vineNumber: $vineNumber
      rowId: $rowId
      polygonId: $polygonId
    ) @client
  }
`;

export const BULK_RATE_VINE_HASURA = gql`
  mutation createVineRating($input: [rating_vinerating_insert_input!]!) {
    insert_rating_vinerating(objects: $input) {
      returning {
        id
        trait_trait {
          id
        }
        trait_traittier {
          trait_trait {
            id
          }
          id
          name
          color
        }
        vineyard_polygon {
          id
          number
        }
        vineyard_vine {
          id
          number
          vineyard_row {
            id
          }
        }
        comment
        created
        numerical_value
        text_value
      }
    }
  }
`;

export const UPDATE_VINE_RATING_HASURA = gql`
  mutation updateVineRating(
    $pk_columns: rating_vinerating_pk_columns_input!
    $input: rating_vinerating_set_input!
  ) {
    update_rating_vinerating_by_pk(pk_columns: $pk_columns, _set: $input) {
      id
      comment
      numerical_value
      text_value
      created
      trait_trait {
        id
      }
      trait_traittier {
        id
        color
        name
        trait_trait {
          id
        }
      }
      vineyard_polygon {
        id
        number
      }
      vineyard_vine {
        id
        number
        vineyard_row {
          id
        }
      }
    }
  }
`;

export const BULK_RATE_VINE = gql`
  mutation rateVine($input: CreateOrUpdateVineRatingMutationBulkInput!) {
    createOrUpdateVineRatingsBulk(input: $input) {
      result {
        vineRatings {
          id
          traitTier {
            id
          }
          vine {
            id
            number
            row {
              id
            }
          }
          comment
        }
      }
    }
  }
`;

export const OFFLINE_CREATED_VINES = gql`
  {
    offlineCreateVines @client {
      newVineNumber
      rowId
      location {
        longitude
        latitude
      }
    }
  }
`;

export const CREATE_VINE_ONLINE_HASURA = gql`
  mutation createVines($input: [vineyard_vine_insert_input!]!) {
    insert_vineyard_vine(objets: $input) {
      returning {
        id
        location
      }
    }
  }
`;

export const CREATE_VINE_ONLINE = gql`
  mutation createVines($input: CreateVineMutationBulkInput!) {
    createVines(input: $input) {
      result {
        vines {
          id
          number
          row {
            id
          }
        }
      }
    }
  }
`;

export const OFFLINE_EDITED_VINES = gql`
  {
    offlineEditVines @client {
      vineNumber
      rowId
      location {
        longitude
        latitude
      }
    }
  }
`;

export const EDIT_VINE_ONLINE_HASURA = gql`
  mutation editVine(
    $pk_columns: vineyard_vine_pk_columns_input!
    $input: vineyard_vine_set_input
  ) {
    update_vineyard_vine_by_pk(pk_columns: $pk_columns, _set: $input) {
      id
      location
    }
  }
`;

export const EDIT_VINE_ONLINE = gql`
  mutation editVines($input: EditVineMutationBulkInput!) {
    editVines(input: $input) {
      result {
        vines {
          id
          number
          row {
            id
          }
        }
      }
    }
  }
`;

export const OFFLINE_DELETED_VINES = gql`
  {
    offlineDeleteVines @client {
      vineNumber
      rowId
    }
  }
`;

export const DELETE_VINE_ONLINE = gql`
  mutation deleteVines($input: DeleteVineMutationBulkInput!) {
    deleteVines(input: $input) {
      result {
        deletedVines {
          vineNumber
          rowId
        }
      }
    }
  }
`;
