import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Button, Icon, Loader, Table } from "semantic-ui-react";
import useReactRouter from "use-react-router";
import useOnlineStatus from "@rehooks/online-status";

import ScrollWrapper from "./../../../components/ScrollWrapper";
import { TRAITS_VIEW } from "./../../../constants/routes";

const GET_TRAITS = gql`
  {
    viewer {
      traits {
        id
        name
        isNumerical
        isText
        project {
          id
          name
        }
        traitTiers {
          order
          name
        }
      }
    }
  }
`;

const TraitsTable = () => {
  const { history } = useReactRouter();
  const online = useOnlineStatus();
  const goToDetail = (id) =>
    history.push({ pathname: `${TRAITS_VIEW.pathname}/${id}` });

  const { data, loading, error } = useQuery(GET_TRAITS, {
    fetchPolicy: online ? "cache-and-network" : "cache-only",
  });

  if (loading) {
    return <Loader active />;
  }
  if (error || !data || !data.viewer) {
    return <p>Traits could not be loaded</p>;
  }
  if (data && data.viewer) {
    return (
      <ScrollWrapper>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Trait classes</Table.HeaderCell>
              <Table.HeaderCell>Numerical</Table.HeaderCell>
              <Table.HeaderCell>Text</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.viewer.traits.map((trait, index) => (
              <Table.Row key={`trait-${index}`}>
                <Table.Cell>{trait.name}</Table.Cell>
                <Table.Cell>{trait.project.name}</Table.Cell>
                <Table.Cell>{trait.traitTiers.length}</Table.Cell>
                <Table.Cell>
                  {trait.isNumerical && (
                    <Icon name="check circle" color="green" />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {trait.isText && <Icon name="check circle" color="green" />}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={() => goToDetail(trait.id)}
                    disabled={!online}
                  >
                    edit
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ScrollWrapper>
    );
  }
  return null;
};

export default TraitsTable;
