import React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";

import { usePosition } from "use-position";
import { point } from "@turf/helpers";

const UserLocation = () => {
  const { latitude, longitude } = usePosition(true, {
    enableHighAccuracy: true,
  });
  const location = point([longitude, latitude]);
  if (latitude && longitude) {
    return (
      <GeoJSONLayer
        data={location}
        circlePaint={{ "circle-color": "blue", "circle-radius": 10 }}
      />
    );
  }
  return null;
};

export default UserLocation;
